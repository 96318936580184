<template>
  <v-container>
    <div v-if="loading">
      <v-overlay :value="loading" absolute>
        <v-progress-circular indeterminate color="primary" />
      </v-overlay>
    </div>
    <div v-else>
      <v-row v-if="vehicles.length > 0">
        <v-col v-for="(vehicle, index) in vehicles" :key="`vehicle_${index}`" cols="6" sm="4" md="3" xl="2" class="d-flex child-flex">
          <vehicle-card :vehicle="vehicle" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-alert type="warning">
            <v-row align="center">
              <v-col class="grow"> There are no vehicles to display. </v-col>
              <v-col class="shrink">
                <v-btn v-if="['super', 'admin'].includes(user.role)" small outlined :to="{ name: 'AdminManageVehicles' }">Admin</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="user && user.role === 'admin'">
        <v-col class="text-center">
          <v-btn color="primary" to="/admin/manage-vehicles"> vehicle manager </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import VehicleCard from '../../components/vehicleTracker/VehicleTrackerCard.vue'
export default {
  name: 'VehicleTrackerList',
  components: {
    VehicleCard
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.userData,
      vehicles: (state) => state.vehicle.vehicles
    })
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions('vehicle', ['fetchVehicles']),
    async getData() {
      this.loading = true
      if (this.vehicles.length === 0) {
        await this.fetchVehicles()
      }
      this.loading = false
    }
  }
}
</script>

<style></style>
